<template>
  <div v-loading="loading" class="width100 page-good min-height700">
    <div class="content-box margin-auto margin-top3 margin-bottom3 search-form">
      <!-- search -->
      <SearchForm @query="gotoStore"/>
    </div>
    <div v-if="!loading" class="content-box margin-auto margin-top6 margin-bottom3 search-form">
      <div class="flex width100">
        <div class="flex1 position-relative">
          <!-- <el-image
            v-if="info.images && info.images.length"
            class="cursor-pointer actives05 img-big"
            :src="info.images[currentImg]"
            :previewSrcList="info.images"
            fit="cover"
          /> -->
          <el-image
            v-if="info.images && info.images.length"
            class="cursor-pointer actives05 img-big"
            :src="info.images[currentImg]"
            @click="imgClick(0,info.images)" 
            fit="cover"
          />
          <!-- <el-image
            v-else
            class="cursor-pointer actives05 img-big"
            :src="defaultImg"
            :previewSrcList="[defaultImg]"
            fit="cover"
          /> -->
          <el-image
            v-else
            class="cursor-pointer actives05 img-big"
            :src="defaultImg"
            @click="imgClick(0,[defaultImg])"
            fit="cover"
          />
          <div class="margin-top1">
            <div v-if="info.images && info.images.length" class="width100 overflow-hidden clear-both-after">
              <div v-for="(item, index) in info.images" :key="index" @click="currentImg = index" class="float-left box-sizing padding-right2">
                <el-image :src="item" fit="cover" class="cursor-pointer actives05 border-radius1 img-small" :class="currentImg === index ? 'border box-shadow10' : ''"/>
              </div>
            </div>
            <div v-else>
              <div class="float-left box-sizing padding-right2">
                <el-image :src="defaultImg" fit="cover" class="cursor-pointer actives05 border-radius1 border box-shadow10 img-small"/>
              </div>
            </div>
          </div>
          <div v-if="dynamicList.length" class="position-absolute top0 left0 width100 box-sizing padding2 textColor10 background-opacity2 z-index100">
            <el-carousel height="20px" indicator-position="none" direction="vertical" :autoplay="true">
              <el-carousel-item v-for="(item, index) in dynamicList" :key="index">
                <span>{{ item.content }}</span>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 信息 -->
        <div class="flex3 margin-left4 fontSize14 textColor03">
          <!-- 名称 -->
          <div>
            <span class="margin-right2" title="配件名称">
                {{ `【${info.name}】` }}
            </span>
            <span class="margin-right2" title="配件编码" v-if="info.code">
              {{ info.code }}
            </span>
            <span class="margin-right2" title="生产码" v-if="info.productionCode">
              {{ info.productionCode }}
            </span>
            <span class="margin-right2" title="配件分类" v-if="info.category">
              {{ info.category }}
            </span>
            <span class="margin-right2" title="品牌" v-if="info.brand">
              {{ info.brand }}
            </span>
            <span class="margin-right2" title="产地" v-if="info.productionPlace">
              {{ info.productionPlace }}
            </span>
            <span class="margin-right2" title="性质" v-if="info.property">
              {{ info.property }}
            </span>
            <span class="margin-right2" title="规格" v-if="info.model">
              {{ info.model }}
            </span>
            <span class="margin-right2" title="车型" v-if="info.vehModel">
              {{ info.vehModel }}
            </span>
            <span class="margin-right2" title="适用车型" v-if="info.avaVehModel">
              {{ info.avaVehModel }}
            </span>
          </div>
          <!-- 描述 -->
          <div class="margin-top2" v-if="info.description">
            {{ info.description }}
          </div>
          <!-- 标签 -->
          <div class="margin-top2" v-if="info.labels && info.labels.length">
            <span v-for="(item,index) in info.labels" :key="index" class="fontSize13 font-weight400 padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
                {{ item }}
            </span>
          </div>
          <div class="margin-top2" v-if="info.minOrderQty && info.dosagePerCar">
            <span class="textColor23 fontSize18 font-weight">{{ info.minOrderQty }}</span>
            <span class="margin-left1 margin-right1">件起购，单车用量</span>
            <span class="textColor23 fontSize18 font-weight">{{ info.dosagePerCar }}</span>
          </div>
          <!-- 价格 -->
          <div class="margin-top2">
            <span>价格：</span>
            <span class="textColor23">￥</span>
            <span class="textColor23 fontSize20 font-weight">
                {{ info.price }}
            </span>
            <span v-if="info.linePrice" class="margin-left4 textColor09" style="text-decoration: line-through">
              ￥{{ info.linePrice }}
            </span>
          </div>
          <!-- 库存 -->
          <div class="margin-top2">
            <div v-if="info.stockShows && info.stockShows.length" class="flex">
              <div v-for="(item, index) in info.stockShows" :key="index" class="margin-right2">
                <span>{{ `${item.name ? item.name : "库存"}：` }}</span>
                <span class="textColor23">{{ item.value }}</span>
              </div>
            </div>
            <div v-else>
              <span>库存：</span>
              <span class="textColor23">无货</span>
            </div>
          </div>
          <!-- 收藏 -->
          <div class="margin-top2 flex flex-center">
            <div v-if="!info.collected" @click="collect" class="fontSize14 flex flex-center margin-right4 cursor-pointer actives05">
              <svg t="1667186659379" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1093" width="16" height="16">
                <path
                  d="M908.8 214.4c-9.6-12.8-19.2-22.4-28.8-32-112-115.2-230.4-105.6-342.4-16-9.6 6.4-19.2 16-28.8 25.6-9.6-9.6-19.2-16-28.8-25.6-112-86.4-230.4-99.2-342.4 16-9.6 9.6-19.2 19.2-25.6 32-134.4 195.2-60.8 387.2 137.6 560 44.8 38.4 89.6 73.6 137.6 102.4 16 9.6 32 19.2 44.8 28.8 9.6 6.4 12.8 9.6 19.2 9.6 3.2 3.2 6.4 3.2 12.8 6.4 3.2 3.2 9.6 3.2 16 6.4 25.6 6.4 64 3.2 89.6-12.8 3.2 0 9.6-3.2 16-9.6 12.8-6.4 28.8-16 44.8-28.8 48-28.8 92.8-64 137.6-102.4C969.6 598.4 1043.2 406.4 908.8 214.4zM736 732.8c-41.6 35.2-86.4 70.4-131.2 99.2-16 9.6-28.8 19.2-44.8 25.6-6.4 3.2-12.8 6.4-16 9.6-6.4 3.2-16 6.4-25.6 9.6-3.2 0-6.4 0-9.6 0-6.4 0-12.8 0-16 0-3.2 0-3.2 0-3.2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-3.2 0-3.2-3.2-3.2 0-6.4-3.2-9.6-3.2-3.2-3.2-9.6-6.4-16-9.6-12.8-6.4-28.8-16-44.8-25.6-44.8-28.8-89.6-60.8-131.2-99.2-179.2-160-243.2-323.2-131.2-489.6 6.4-9.6 16-16 22.4-25.6 89.6-96 182.4-86.4 275.2-12.8 9.6 6.4 16 12.8 22.4 19.2 0 0 0 0 0 0l28.8 32c3.2 3.2 3.2 3.2 6.4 6.4 0 0 0 0 0 0l0 0c3.2-3.2 9.6-9.6 16-16 12.8-12.8 25.6-25.6 41.6-38.4 92.8-73.6 185.6-83.2 275.2 12.8 6.4 9.6 16 16 22.4 25.6C982.4 406.4 918.4 572.8 736 732.8z"
                  fill="#8a8a8a" p-id="1094"></path>
              </svg>
              <span class="margin-left2">收藏</span>
            </div>
            <div v-else @click="delCollectibleGoods" class="fontSize14 flex flex-center margin-right4 cursor-pointer actives05">
              <svg t="1667186806096" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1247" width="16" height="16">
                <path
                  d="M892.543016 224.150106c-9.284457-11.914354-17.804505-21.814842-26.454512-30.930453C759.437485 80.827887 642.682341 92.003414 536.033369 176.799682c-9.493212 7.547907-18.453281 15.383362-26.88737 23.346731-8.43409-7.963369-17.395182-15.798824-26.888394-23.346731C375.608633 92.003414 258.853489 80.827887 152.202471 193.21863c-8.650007 9.115612-17.170055 19.016099-25.559119 29.714765C-2.680039 410.134984 68.411089 595.897805 259.728416 764.030084c42.320874 37.192064 87.560218 70.64906 132.799562 99.905384 15.841803 10.245342 30.570249 19.244296 43.816948 26.932396 8.024767 4.657067 13.827937 7.872295 17.044188 9.578146 4.869914 2.916423 9.728572 5.142114 14.530948 6.771217 3.470031 1.619894 7.516184 3.091408 12.218276 4.387937 25.377994 6.998391 62.97938 1.908466 85.839017-11.764951 2.14178-1.101077 7.944949-4.315282 15.969717-8.972349 13.246699-7.688099 27.974122-16.687054 43.816948-26.932396 45.239344-29.256324 90.478687-62.71332 132.799562-99.905384C949.879885 595.897805 1020.971014 410.134984 892.543016 224.150106z"
                  fill="#d81e06" p-id="1248"></path>
              </svg>
              <span class="margin-left2">已收藏</span>
            </div>
            <div @click="copyLink" class="fontSize14 flex flex-center margin-right4 cursor-pointer actives05">
              <svg t="1667186930963" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1401" width="16" height="16">
                <path
                  d="M558.933333 699.733333l-170.666666-93.866666c-25.6 21.333333-55.466667 34.133333-89.6 34.133333-72.533333 0-128-55.466667-128-128s55.466667-128 128-128c38.4 0 68.266667 17.066667 93.866666 38.4L597.333333 320V298.666667c0-72.533333 55.466667-128 128-128s128 55.466667 128 128-55.466667 128-128 128c-34.133333 0-64-12.8-85.333333-34.133334l-213.333333 110.933334v21.333333l174.933333 98.133333c21.333333-17.066667 51.2-29.866667 81.066667-29.866666 72.533333 0 128 55.466667 128 128s-55.466667 128-128 128-128-55.466667-128-128c0-4.266667 0-12.8 4.266666-21.333334zM298.666667 554.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667-42.666667 17.066667-42.666667 42.666667 17.066667 42.666667 42.666667 42.666667z m426.666666-213.333334c25.6 0 42.666667-17.066667 42.666667-42.666666s-17.066667-42.666667-42.666667-42.666667-42.666667 17.066667-42.666666 42.666667 17.066667 42.666667 42.666666 42.666666z m-42.666666 426.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666666-42.666667 17.066667-42.666667 42.666666 17.066667 42.666667 42.666667 42.666667z"
                  fill="#F3D146" p-id="1402"></path>
              </svg>
              <span class="margin-left2">复制链接</span>
            </div>
            <div @click="sendMsg()" class="fontSize14 flex flex-center margin-right4 cursor-pointer actives05">
              <svg t="1667186969453" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1555" width="16" height="16">
                <path
                  d="M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z"
                  fill="#F3D146" p-id="1556"></path>
              </svg>
              <span class="margin-left2">联系商家</span>
            </div>
            <div class="flex1"></div>
          </div>
          <!-- 浏览信息 -->
          <div class="margin-top2 fontSize12 textColor09">
            <span class="margin-right2">{{ info.collections || "0" }}人关注</span>
            <span class="margin-right2">{{ info.browses || "0" }}人浏览</span>
            <span class="margin-right2">{{ info.consultations || "0" }}人咨询</span>
            <span class="margin-right2">{{ info.shares || "0" }}人分享</span>
          </div>
          <!-- 公司信息 -->
          <div class="margin-top2 flex align-items-center">
            <span @click.stop="jumpStore(info.companyId)" class="textColor23 cursor-pointer">
              {{ info.companyName }} >
            </span>
          </div>
          <!-- 公司信息 -->
          <div class="margin-top2 flex align-items-center">
            <span>联系人: {{ info.companyContacts }} {{ info.companyPhone }}</span>
          </div>
        </div>
      </div>
      <div class="margin-top6 width100">
        <el-tabs v-model="activeName">
          <el-tab-pane label="商品详情" name="first">
            <p class="fontSize14 textColor09">商品参数</p>
            <div class="flex content-box-info">
              <div class="flex1-5">
                <p>配件编码：{{ info.code || "-" }}</p>
                <p>配件名称：{{ info.name || "-" }}</p>
                <p>配件品牌：{{ info.brand || "-" }}</p>
                <p>产地：{{ info.productionPlace || "-" }}</p>
                <p>性质：{{ info.property || "-" }}</p>
                <p>配件类别：{{ info.partType || "-" }}</p>
                <p>配件分类：{{ info.category || "-" }}</p>

                <p>车型：{{ info.vehModel || "-" }}</p>
                <p>适用汽车厂牌：{{ info.avaMnemonic || "-" }}</p>
                <p>适用车型：{{ info.avaVehModel || "-" }}</p>
              </div>
              <div class="flex1">
                <p>品质：{{ info.quality || "-" }}</p>
                <p>质保期（天）：{{ info.guarPeriod || "-" }}</p>
                <p>生产码：{{ info.productionCode || "-" }}</p>
                <p>部位码：{{ info.posCode || "-" }}</p>
                <p>特征码：{{ info.featureCode || "-" }}</p>
                <p>发动机号：{{ info.engineNo || "-" }}</p>
                <p>条形码：{{ info.barCode || "-" }}</p>
              </div>
              <div class="flex1">
                <p>规格：{{ info.model || "-" }}</p>
                <p>包装数：{{ info.packingNum || "-" }}</p>
                <p>长度（单位：m）：{{ info.length || "-" }}</p>
                <p>宽度（单位：m）：{{ info.width || "-" }}</p>
                <p>高度（单位：m）：{{ info.height || "-" }}</p>
                <p>体积（单位：m³）：{{ info.volume || "-" }}</p>
                <p>重量（单位：kg）：{{ info.weight || "-" }}</p>
              </div>
              <div class="flex1">
                <p>每车用量：{{ info.dosagePerCar || "-" }}</p>
                <p>起订量：{{ info.minOrderQty || "-" }}</p>
                <p>计量单位：{{ info.unit || "-" }}</p>
              </div>
            </div>
            <el-divider v-if="info.remarks"></el-divider>
            <p v-html="info.remarks"></p>
            <div class="overflow-hidden clear-both-after margin-top4 margin-bottom4">
              <!-- <el-image
                v-for="(item, index) in info.infoImages"
                :key="index"
                class="float-left width25 box-sizing padding-right2 margin-bottom2 cursor-pointer box-shadow5"
                :src="item"
                :previewSrcList="info.infoImages"
                fit="cover"
              /> -->
              <el-image
                v-for="(item, index) in info.infoImages"
                :key="index"
                class="float-left width25 box-sizing padding-right2 margin-bottom2 cursor-pointer box-shadow5"
                :src="item"
                @click="imgClick(index,info.infoImages)" 
                fit="cover"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <fixedNav/>
  </div>
</template>
<script>
import SearchForm from './components/searchForm'
import fixedNav from "@/components/fixedNav/fixedNav";
import {getGoodsDetailApi} from "@/api/home/goods";
import {collect, delCollectibleGoodsApi} from "@/api/collection";
import {dynamicListApi} from "@/api/home/log";
import {getContactStaffs} from "@/api/home/chat";
import eventBus from "@/event";

export default {
  components: {
    SearchForm,
    fixedNav
  },
  data() {
    return {
      defaultImg: require("@/assets/maintenance/default-parts.png"),
      currentImg: 0,
      info: {},
      dynamicList: [],
      activeName: "first",
      loading: true
    };
  },
  created() {
    this.init();
  },
  methods: {
    imgClick(index,images){
          eventBus.$emit("ShowImageViewer", {
          index: index,
          list: images
        });
    },
    init() {
      this.loading = true;
      getGoodsDetailApi({
        id: this.$route.query.goodId,
      }).then((res) => {
        this.info = res;
        this.loading = false;
        dynamicListApi({
          objectType: "part",
          objectId: res.swPartId,
        }).then((res) => {
          this.dynamicList = [...res];
        });
      });
    },
    gotoStore(e) {
      this.$router.push(`/home/store?storeId=${this.info.companyId}&query=${e}`);
    },
    jumpStore(id) {
      this.$router.push("/home/store?storeId=" + id);
    },
    collect() {
      if (!this.$store.state.profile.id) {
        this.$router.push('/login')
        return
      }
      collect({
        goodsId: this.info.swPartId,
        storeId: this.info.companyId,
      }).then((res) => {
        this.init();
      });
    },
    delCollectibleGoods() {
      delCollectibleGoodsApi({
        type: "specified",
        id: this.info.id,
      }).then((res) => {
        this.init();
      });
    },
    copyLink() {
      const link = `${location.origin}/home/good?goodId=${this.info.id}`;
      const _this = this;
      this.$copyText(link).then(
        function (e) {
          _this.$message({
            message: "已复制到剪贴板",
            type: "success",
          });
        },
        function (e) {
          _this.$message({
            message: "复制失败",
            type: "error",
          });
        }
      );
    },
    sendMsg() {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      getContactStaffs({
        companyId: this.info.companyId,
      }).then((res) => {
        this.$store.dispatch("setChatId", {
          ...this.info,
          userId: res[0].userId
        });
        this.$store.dispatch("changeIMVisible", true);
      });
    },
  },
};
</script>
<style scoped>
.page-good .img-big {
  width: 300px;
  height: 300px;
}

.page-good .img-small {
  width: 50px;
  height: 50px;
}

.page-good .content-box-info {
  line-height: 2em;
}
</style>

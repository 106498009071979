<template>
  <div class="chat_box_wrap" v-drag>
    <div v-if="showFirst">
      <div class="chat_div  cursor-pointer bg-warning recommend">
        <div class="chat_label text-xs" style='color:white'>推荐</div>
        <div class="chat_label text-xs" style='color:white'>商家</div>
      </div>
    </div>
    <div @click.stop="openMsg" v-show="profile.id" class=" relative border-b">
      <div class="chat_div  cursor-pointer">
        <i class="iconfont icon-line_message1 icon_chat "></i>
        <div class="chat_label">消息</div>
      </div>
      <span class="count" v-if="count > 0 && count < 100">{{ count }}</span>
      <span class="count" v-if="count >= 100">99+</span>
    </div>
    <div>
      <div class="chat_div cursor-pointer" @click="gotoTop">
        <div class="chat_label text-xs">回到</div>
        <div class="chat_label text-xs">顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  audioPlay,
  audioReceiveEnquiryPlay,
  audioWinOfferPlay,
  audioOfferPlay
} from "@/utils/audio.js";
import { mapState } from "vuex";
let isDrag = false;
export default {
  name: "iconChat",
  props: {
    showFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socket: null,
      count: 0,
    };
  },
  computed: {
    userInfo() {
      return this.profile;
    },
    ...mapState({
      name: (state) => state.profile.name,
      profile: (state) => state.profile,
    }),
  },
  async mounted() {
    this.count = Number(localStorage.getItem("msgCount") || 0);
    window.z = this;
    window.onmessage = function(e) {
      // console.log("e.data", e.data); // 操作
      // 忽略webpack的触发
      if (e.data.operate) {
        // console.log(e.data.operate) // 操作名
        // console.log(e.data.data) // 父窗口给子窗口的数据
        window.z.count = e.data.data;
        localStorage.setItem("msgCount", String(e.data.data || 0));
        if (e.data.data > 0) {
          if (e.data.clickType === "receiveEnquiry" || e.data.msgType === "enquiry") {
            audioReceiveEnquiryPlay();
          } else if (e.data.clickType === "winOffer") {
            audioWinOfferPlay();
          } else if (e.data.msgType === "offer" || e.data.clickType === "receiveOffer") {
            audioOfferPlay();
          } else {
            audioPlay();
          }
        }
      }
    };
  },
  methods: {
    openMsg() {
      if (isDrag) return;
      this.count = 0;
      this.$store.dispatch("setChatId", 0);
      this.$store.dispatch("changeIMVisible", true);
    },
    gotoTop(){
      window.scrollTo(0,0);
      this.$APPScrollTop(0);
    }
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function(el) {
        let odiv = el; //获取当前元素
        el.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          let left = "";
          let top = "";
          const clientX = e.clientX;
          const clientY = e.clientY;
          document.onmousemove = (e) => {
            if (clientX > e.clientX - 3 && clientX < e.clientX + 3) {
              return;
            }
            if (clientY > e.clientY - 3 && clientY < e.clientY + 3) {
              return;
            }
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            isDrag = true;
            left = e.clientX - disX;
            top = e.clientY - disY;
            var docWidth = document.body.clientWidth; //网页可见宽
            var docHeight = document.body.clientHeight; //网页可见高
            if (top > docHeight - 60) {
              //超下边界(键盘DIV长宽分别为 60px、60px)
              top = docHeight - 60;
            }
            if (top < 0) {
              //超上边界
              top = 0;
            }
            if (left > docWidth - 60) {
              //超右边界
              left = docWidth - 60;
            }
            if (left < 0) {
              //超左边界
              left = 0;
            }
            //绑定元素位置到positionX和positionY上面
            //移动当前元素
            odiv.style.left = left + "px";
            odiv.style.top = top + "px";
          };
          document.onmouseup = () => {
            document.onmousemove = null;
            document.onmouseup = null;
            setTimeout(() => {
              isDrag = false;
            }, 200);
          };
        };
      },
    },
  },
};
</script>

<style lang="less" scoped>
.chat_box_wrap {
  position: fixed;
  top: 50%;
  left: 94%;
  width: 60px;
  background: #fff;
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #c0c4cc;
  //overflow: hidden;

  .recommend{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .chat_div {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666666;
    padding: 8px 0;
    .icon_chat {
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 2px;
    }
    .chat_label {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .count {
    position: absolute;
    top: -5px;
    right: -5px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: red;
    text-align: center;
    color: white;
  }
}
</style>

import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

//员工申请加入公司
// export function joinCompany(data) {
//     return request({
//         baseURL: SERVICE_MODULE_MAP.coreModule,
//         url: "/account/staffs/apply",
//         method: "post",
//         data
//     });
// }

//商品查询
export function getGoodsApi(params) {
  return request({
      baseURL: SERVICE_MODULE_MAP.coreModule,
      url: "/goods/search",
      method: "get",
      params
  });
}

//商品查询筛选信息
export function getGoodsOptionsApi(params) {
  return request({
      baseURL: SERVICE_MODULE_MAP.coreModule,
      url: "/goods/search/options",
      method: "get",
      params
  });
}

//商品查询筛选信息
export function getGoodsFilterApi(params) {
  return request({
      baseURL: SERVICE_MODULE_MAP.coreModule,
      url: "/goods/search/filter",
      method: "get",
      params
  });
}

//商品主页/商品详情
export function getGoodsDetailApi(params) {
  return request({
      baseURL: SERVICE_MODULE_MAP.coreModule,
      url: "/goods/search/" + params.id,
      method: "get"
  });
}
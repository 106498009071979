import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

//获取个人信息
export function getUserInfo() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/profile",
    method: "GET",
  })
}
//获取内部通讯录
export function getContactStaffs(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/staffs",
    method: "GET",
    params
  })
}
//获取erp往来单位列表
export function getCustomerList(){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/cooperator/erp",
    method: "GET"
  })
}
//获取系统消息（联系人列表）
export function getSystemMessageList(){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/system-message/list",
    method: "GET"
  })
}
//获取系统消息列表
export function getSystemMsgList(params){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/system-message/msg-list",
    method: "GET",
    params
  })
}
//[访客可用]获取联系人
export function getContractList(params){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact",
    method: "GET",
    params
  })
}
//[访客可用]按最后聊天时间获取联系人
export function getContractLastList(params){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/last-list",
    method: "GET",
    params
  })
}
//设为已读[访客可用]
export function setMessageReded(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/message/read",
    method: "POST",
    data
  })
}
//获取聊天历史记录[访客可用]
export function getChatHistory(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/message/list",
    method: "GET",
    params
  })
}
//获取关联相同往来单位的其他联系人
export function getOtherContract(contactId) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/customer-contacts",
    method: "POST",
    data: {contactId}
  })
}
//标签列表
export function getContractLabel(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/labels",
    method: "GET",
    params
  })
}
//创建标签
export function addContractLabel(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/labels",
    method: "POST",
    data
  })
}
//更新标签
export function setContractLabel(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/settings/labels/${data.id}`,
    method: "POST",
    data
  })
}
//删除标签
export function delLabels(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/settings/labels/delete`,
    method: "POST",
    data
  })
}
//删除联系人标签
export function delContractLabel(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/im/contact/del-label`,
    method: "POST",
    data
  })
}
//设置联系人标签
export function bindContractLable(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/set-label",
    method: "POST",
    data
  })
}
//绑定本地往来单位
export function bindCooperation(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/bind",
    method: "POST",
    data
  })
}

//员工列表
export function getAccountList(params){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs",
    method: "GET",
    params
  })
}
//上传文件
export function uploadImage(params){
  const form = new FormData();
  if (Array.isArray(params.file)) {
    params.file.forEach(file => {
      form.append("file", file, file.name);
    });
  } else {
    form.append("file", params.file, params.file.name);
  }
  return request({
    baseURL: SERVICE_MODULE_MAP.generalModule,
    url: "/files/upload",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    timeout: 60 * 1000,
    data:form
  })
}
//获取内部通讯录
export function getContractByPlatform(params){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/platform-user",
    method: "GET",
    params
  })
}
//设置联系人为不可见
export function setContractHide(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/hide",
    method: "POST",
    data
  })
}
//设置联系人备注
export function setRemark(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/set-remark",
    method: "POST",
    data
  })
}
//[访客可用]通过用户ID或者联系人ID获取联系人详情，联系人不存在则创建
export function getContractInfo(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/info",
    method: "POST",
    data
  })
}
//未读消息条数
export function getUnreadCount(params){
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/im/contact/unread-count",
    method: "GET",
    params
  })
}
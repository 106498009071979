import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//创建历史日志
export function createHistoryLogsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/logs/history",
    method: "post",
    data
  });
}

//历史日志列表
export function historyLogsListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/logs/history",
    method: "get",
    params
  });
}

//清空历史日志
export function clearAllLogsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/logs/history",
    method: "delete",
    data
  });
}

//计数
export function countsLogsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/logs/counts",
    method: "post",
    data
  });
}

export function dynamicListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/logs/dynamic",
    method: "get",
    params
  });
}
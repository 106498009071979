import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//我的收藏商品列表
/**
  "filterOff": false,	 # 过滤下架商品
  "filterNoPrice": false,	 # 过滤无价格商品，暂无效果
  "filterNoStock": false,	 # 过滤无库存商品，暂无效果
  "page": 0,	 # 页码
  "pageSize": 0	 # 页宽

*/
export function getCollectibleListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/collectible-goods",
    method: "get",
    params
  });
}

export function collect(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/collectible-goods",
    method: "post",
    data
  });
}

//收藏商品
/*
"goodsId": 0,	 # 收藏商品ID,软件内配件ID(swPartId)
 "storeId": 0	 # 收藏商品的店铺ID(companyId)
*/
export function addCollectibleGoodsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/collectible-goods",
    method: "post",
    data
  });
}

//删除收藏
/*
"type": "",	 # specified: 删除指定商品收藏, all: 删除所有商品收藏, off: 删除已下架商品收藏
"id": 0	 # 收藏ID，Type为specified时有效
*/
export function delCollectibleGoodsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/collectible-goods",
    method: "delete",
    data
  });
}
//根据公司商品信息删除收藏
export function delCollectibleGoodsByGoodsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/collectible-goods/by-goods",
    method: "delete",
    data
  });
}